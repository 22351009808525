<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardText>
            <VForm
              ref="form"
            >
              <VContainer fluid>
                <VRow
                  align="center"
                >
                  <VCol md="6">
                    <div>
                      <strong>Имя</strong>
                    </div>
                    <VTextField
                      v-model="entity.name"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol md="4">
                    <VBtn
                      large
                      depressed
                      color="primary"
                      class="mr-3"
                      @click="save"
                    >
                      Сохранить
                    </VBtn>
                    <VBtn
                      large
                      text
                      depressed
                      @click="$router.push({ name : Names.R_ACCOUNT_SYS_COMPANIES })"
                    >
                      Отмена
                    </VBtn>
                  </VCol>
                </VRow>
              </VContainer>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'SysCompanyItemEdit',
  inject: ['Names'],
  data() {
    return {
      entity: {},
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.$di.api.Account.getCompany({ id: this.id })
      .then((r) => {
        this.entity = { ...this.entity, ...r.company };
      })
      .catch((err) => {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      });
  },
  methods: {
    save() {
      const { entity } = this;
      const { active, accountId, ...rest } = entity;
      this.$di.api.Account.updateCompany({ company: rest })
        .then(() => this.$di.notify.snackSuccess('Компания успешно обновлена'))
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
  .dark {
    border-color: orange !important;
    border-width: 2px;
  }
</style>
